import React, { useState, useEffect } from "react";
import { ReactComponent as BugerIcon } from "../../../../../style/img/burger_icon.svg";
import TableNavSetting from "./setting_category/TableNavSetting";
import TableSettingSubCategory from "./setting_subCat/TableSettingSubCategory";
import { useSelector } from "react-redux";
const RenderMenuItem = ({
  menuItem,
  onSelect,
  selectedCategory,
  createNewCategory,
  styleWrapper = true,
  movingChecked,
  movingProductToCategory,
}) => {
  const openCategoryStore = useSelector(
    (state) => state.table.selectedCategoryGroup
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      openCategoryStore &&
      openCategoryStore.length > 0 &&
      openCategoryStore.includes(menuItem.id)
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openCategoryStore]);
  const handleOpen = (item) => {
    onSelect(item);

    if (selectedCategory.id === item.id) {
      setOpen(!open);
    } else setOpen(true);
  };
  const handleSelect = (item) => {
    onSelect(item);
    if (movingChecked) {
      movingProductToCategory(item.id);
    }
  };
  if (menuItem.subMenu) {
    return (
      <div
        className="table_sideitem_wrapper"
        style={
          open && styleWrapper
            ? {
                borderTop: "1px solid #F0F0F0",
                borderBottom: " 1px solid #F0F0F0",
                padding: "5px 0",
              }
            : {}
        }
      >
        <div
          className={`table_sideitem ${open && "active"} ${
            selectedCategory?.id === menuItem.id && "selected"
          }`}
          style={
            selectedCategory?.id === menuItem.id
              ? { background: "#0D7040" }
              : undefined
          }
        >
          {menuItem.icon}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              width: "100%",
            }}
            onClick={() => handleOpen(menuItem)}
          >
            <span className="table_sideitem_titel">{menuItem.name_ua}</span>
            <BugerIcon />
          </div>

          {selectedCategory?.id === menuItem.id && createNewCategory && (
            <TableNavSetting category_id={menuItem.id} cat_data={menuItem} />
          )}
        </div>

        <div
          className="table_aside_submenu"
          style={open ? { display: "block" } : undefined}
        >
          {menuItem.subMenu.map((subItem) => {
            return (
              <div
                key={subItem.id}
                onClick={() => handleSelect(subItem)}
                className={`table_aside_submenu_wrapper ${
                  selectedCategory?.id === subItem.id && "active"
                }`}
              >
                <span className="table_aside_submenu_text" key={subItem.id}>
                  {subItem.name_ua}
                </span>
                {selectedCategory?.id === subItem.id && createNewCategory && (
                  <TableSettingSubCategory
                    category_id={subItem.id}
                    cat_data={subItem}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div
      key={menuItem.id}
      onClick={() => handleSelect(menuItem)}
      className={`table_sideitem ${
        selectedCategory?.id === menuItem.id && "selected"
      }`}
      style={
        selectedCategory?.id === menuItem.id
          ? { background: "#0D7040" }
          : undefined
      }
    >
      {menuItem.icon}
      <span style={{ padding: "10px" }} className="table_sideitem_titel">
        {menuItem.name_ua ? menuItem.name_ua : menuItem.name}
      </span>
      {selectedCategory?.id === menuItem.id &&
        menuItem.id !== "without" &&
        createNewCategory &&
        selectedCategory !== null && (
          <TableNavSetting category_id={menuItem.id} cat_data={menuItem} />
        )}
    </div>
  );
};

export default RenderMenuItem;
