import React from "react";
import ProductFilterInputReq from "./ProductFilterInputReq";
import ProductFilterInputAdditional from "./ProductFilterInputAdditional";

const ProductFiltersForm = ({
  attributesReq,
  onChangeAttributeReq,
  attributesAdd,
  onChangeAttributeAdd,
  attributesAddNew,
  OnChangeAttributeNew,
  submitNewAttribute,
  cancelAttributeAdd,
  cancelAttributeReq,
  disable = true,
}) => {
  return (
    <div className="productFilters">
      <div className="productFilters_additional">
        <span>Характеристики</span>
        <div>
          <ProductFilterInputAdditional
            data={attributesAddNew}
            onChangeAttribute={OnChangeAttributeNew}
            disable={!disable}
          />
          <div className="productFilters_additional_button">
            <button
              type="button"
              onClick={() => submitNewAttribute(attributesAddNew)}
            >
              Додати
            </button>
          </div>
        </div>
        <div className="productFilters_additional_cards">
          {attributesAdd.length > 0 &&
            attributesAdd.map((item) => (
              <ProductFilterInputAdditional
                key={item.id}
                data={item}
                onChangeAttribute={onChangeAttributeAdd}
                cancel={cancelAttributeAdd}
                disable={!disable}
              />
            ))}
        </div>
      </div>
      <div className="productFilters_req">
        <span>Обов’язкові характеристики для фільтру</span>
        <div style={{ paddingRight: "40px" }}>
          {attributesReq &&
            attributesReq.map((item) => (
              <ProductFilterInputReq
                key={item.id}
                data={item}
                cancel={cancelAttributeReq}
                onChangeAttribute={onChangeAttributeReq}
                disable={!disable}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductFiltersForm;
