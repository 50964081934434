import { Route, Routes, Navigate } from "react-router-dom";

import AsideBarNav from "./nav/AsideBarNav";
import Catalogue from "./catalogue/Catalogue";
import CatalogueCreateProduct from "./catalogue/components/CatalogueCreateProduct";
import CatalogueDetails from "./catalogue/components/CatalogueDetails";
import CatalogueAddingProducts from "./catalogue/components/CatalogueAddingProducts";

import Counterparty from "./counterparty/Counterparty";
import CounterpartyCreate from "./counterparty/components/CounterpartyCreate";
import CounterpartyDetails from "./counterparty/components/CounterpartyDetails";

import User from "./user/User";
import UserCreate from "./user/components/UserCreate";

import Sell from "./sell/Sell";
import SellCreate from "./sell/components/SellCreate";
import SellDetails from "./sell/components/SellDetails";

import CounterpartySearch from "./counterparty/components/CounterpartySearch";

import Setting from "./settingPage/Setting";
import SettingCreate from "./settingPage/components/SettingCreate";

const Main = () => {
  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        width: "100vw",
        background: "#F3FAF8",
      }}
    >
      <AsideBarNav />

      <Routes>
        <Route path="/" element={<Navigate to="/catalogue" replace />} />

        <Route path="/catalogue/*" element={<Catalogue />} />
        <Route path="/catalogue/create" element={<CatalogueCreateProduct />} />
        <Route path="/catalogue/details" element={<CatalogueDetails />} />

        <Route path="/counterparty/*" element={<Counterparty />} />
        <Route path="/counterparty/create" element={<CounterpartyCreate />} />
        <Route path="/counterparty/details" element={<CounterpartyDetails />} />

        <Route path="/users/*" element={<User />} />
        <Route path="/users/create" element={<UserCreate />} />

        <Route path="/sell/*" element={<Sell />} />
        <Route path="/sell/details" element={<SellDetails />} />
        <Route path="/sell/create/*" element={<SellCreate />} />
        <Route
          path="/sell/create/search"
          element={
            <CounterpartySearch
              type={"custom"}
              addCounerpartyLink={"/sell/create"}
            />
          }
        />
        <Route
          path="/sell/create/catalouge"
          element={<CatalogueAddingProducts navigate={"/sell/create"} />}
        />

        <Route path="/setting/*" element={<Setting />} />
        <Route path="/setting/create" element={<SettingCreate />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </div>
  );
};

export default Main;
