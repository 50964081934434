import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ApplicationCatalogue from "../../../../useHook/useTable/components/applicationCatalogue/ApplicationCatalogue";
import button_back from "../../../../style/img/button_back.png";
import Exel from "../../../../saveExcel/Exel";
import UseDropDown from "../../../../useHook/dropDown/UseDropDown";
import {
  getInvoiceById,
  updateInvoiceById,
} from "../../../../store/slices/tableSlice";
import { sendPaymentPost } from "../../../../store/slices/paymentSlice";
import { getSettingByType } from "../../../../store/slices/settingSlice";
import { statusData, paymentsData } from "../../../../utils/statusData";

const SellDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [invoicesItems, setInvoicesItems] = useState([]);
  const [data, setData] = useState(false);

  // payment
  const [payment, setPayment] = useState();

  const handlePayment = (item) => {
    setPayment(item);
  };

  const dataId = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];

  const invoicesCounterparty = dataId && dataId.counterparty_name;
  const emailCounterparty = dataId && dataId.counterparty_email;
  const phoneCounterparty = dataId && dataId.counterparty_phone;

  const getInvoiceItems = async () => {
    const req = await dispatch(getInvoiceById(dataId.id));
    const payload = req.payload.invoice;
    const items = payload.CounterpartiesInvoicesItems;

    const itemsForJsx =
      items &&
      items.map(({ Product, quantity, price, price_in_uah }) => ({
        quantity_application: quantity,
        price_sum: quantity * price,
        price_in_uah: quantity * price_in_uah,
        ...Product,
      }));
    setInvoicesItems(itemsForJsx);
    setData(payload);

    if (payload.Payment) {
      const findPayment = paymentsData.filter(
        (item) => item.id === payload.Payment.status
      )[0];
      setPayment(findPayment);
    }
  };
  useEffect(() => {
    getInvoiceItems();
  }, []);
  // status
  const [checkedStatus, setCheckedStatus] = useState();
  const handleStatus = (item) => {
    setCheckedStatus(item);
  };

  useEffect(() => {
    if (data) {
      const findStatus = statusData.filter(
        (item) => item.id === data.status
      )[0];
      setCheckedStatus(findStatus);
    }
  }, [data]);

  // discount
  const discounts = useSelector((state) => state.setting.data);
  const [checkedDiscount, setCheckedDiscount] = useState();
  const handleDiscount = (item) => {
    setCheckedDiscount(item);
  };
  useEffect(() => {
    dispatch(getSettingByType({ type: "discount" }));
    if (discounts && data) {
      const findDiscount = discounts.filter(
        (item) => item.value === data.discount_value
      )[0];
      if (findDiscount) {
        setCheckedDiscount({
          id: findDiscount.id,
          name: findDiscount.name,
          value: findDiscount.value,
        });
      }
    }
  }, [data]);

  return (
    <div className="purchaseCreate">
      <div className="purchaseCreate_head">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              onClick={() => navigate("/sell")}
              src={button_back}
              alt="back"
            />
            <span>Заявка</span>
          </div>
          <div>
            <button
              style={{ margin: "0 16px 0 0" }}
              className="catalogueCreate_submit"
              type="button"
              onClick={async () => {
                const req = await dispatch(
                  sendPaymentPost({
                    to: emailCounterparty,
                    orderNumber: dataId?.id,
                    price: dataId.price_sum_UAH,
                  })
                );
                if (req.payload === 200) {
                  alert("Лист із реквізитами надіслано");
                }
              }}
            >
              Лист оплати
            </button>
            <Exel data={invoicesItems} title={"Накладна"} />
          </div>
        </div>
      </div>
      <Formik
        initialValues={{
          comment: data.comment ? data.comment : "",
        }}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          let bodyData = {};
          bodyData.payment_status = payment.id;
          bodyData.status = checkedStatus.id;
          bodyData.id = dataId.id;
          const req = await dispatch(updateInvoiceById(bodyData));
          if (req.payload === 200) {
            getInvoiceItems();
            setEdit(false);
          }
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div style={{ width: "100%" }}>
              <div className="catalogueCreate_input_top">
                <div
                  className="catalogueCreate_input_top_first"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "25%" }}>
                    <span>Постачальники</span>
                    <div style={{ display: "flex" }}>
                      <span className="purchaseCounterpary">
                        {invoicesCounterparty && invoicesCounterparty}
                      </span>
                    </div>
                  </div>
                  <div style={{ width: "25%" }}>
                    <span>Email</span>
                    <div style={{ display: "flex" }}>
                      <span className="purchaseCounterpary">
                        {emailCounterparty && emailCounterparty}
                      </span>
                    </div>
                  </div>
                  <div style={{ width: "25%" }}>
                    <span>Номер телефону</span>
                    <div style={{ display: "flex" }}>
                      <span className="purchaseCounterpary">
                        {phoneCounterparty && phoneCounterparty}
                      </span>
                    </div>
                  </div>

                  {/* <ErrorMessage name="login" className="error " component="div" /> */}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "25%" }}>
                    <span>Статус</span>
                    <UseDropDown
                      selectedItem={checkedStatus}
                      handleChange={handleStatus}
                      data={statusData}
                      disabled={!edit}
                    />
                  </div>
                  <div style={{ width: "25%" }}>
                    <span>Тип цін</span>
                    <UseDropDown
                      selectedItem={checkedDiscount}
                      handleChange={handleDiscount}
                      data={discounts}
                      disabled={true}
                    />
                  </div>
                  <div style={{ width: "25%" }}>
                    <span>Оплата</span>
                    <UseDropDown
                      selectedItem={payment}
                      handleChange={handlePayment}
                      data={paymentsData}
                      disabled={!edit}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="catalogueCreate_input_top_textarea">
              <span>Коментар</span>
              <Field disabled as="textarea" type="text" name="comment" />
            </div>
          </div>

          <ApplicationCatalogue
            handleCounterApplication={() => {}}
            onChangeApplicationQuantity={() => {}}
            cancelItemTableFunc={() => {}}
            checkedRow={invoicesItems}
            setcheckedRow={() => {}}
            styleTable={{
              minHeight: "calc(100vh - 412px)",
              maxHeight: "calc(100vh - 412px)",
              padding: "0",
            }}
            button={false}
            toggle={false}
          />

          <div className="detailsButton_wrapper">
            <div>
              {edit && (
                <button className="catalogueCreate_submit" type="submit">
                  Зберегти
                </button>
              )}
            </div>

            <button
              type="button"
              className="catalogueCreate_submit"
              onClick={() => setEdit((state) => !state)}
            >
              Редагувати
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default SellDetails;
