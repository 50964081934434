import HeaderTable from "../HeaderTable";
import BodyTable from "../BodyTable";
import { useNavigate } from "react-router-dom";
import { setCheckboxData } from "../../../../store/slices/tableSlice";
import { useDispatch } from "react-redux";
import button_back from "../../../../style/img/button_back.png";
import { columnApplication } from "./columnApplication";
const ApplicationCatalogue = ({
  checkedRow,
  handleCounterApplication,
  setcheckedRow,
  styleTable = {},
  button = true,
  toggle = true,
  navigateToHomePage,
  cancelItemTableFunc,
  onChangeApplicationQuantity,
  marginTopStyle = {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const column = toggle
    ? columnApplication
    : columnApplication.filter(({ accessor }) => accessor !== "toggle_q");
  const addCheckedData = () => {
    dispatch(setCheckboxData(checkedRow));
    navigate(navigateToHomePage);
  };

  return (
    <>
      <div style={marginTopStyle}>
        {button && (
          <div className="table_button_wrapper_applicationsCatalog">
            <div className="applicationsCatalog_back">
              <img onClick={() => navigate(-1)} src={button_back} alt="back" />
              <span>Список доданих товарів</span>
            </div>
            <div className="applicationsCatalog_buttonSecond">
              <button onClick={() => setcheckedRow([])}>Очистити</button>
              <button onClick={() => addCheckedData()}>Додати</button>
            </div>
          </div>
        )}

        <div style={styleTable} className="wrapper_table_applicationsCatalog">
          <table>
            <thead>
              <tr>
                <HeaderTable
                  columns={column}
                  applicationHeader={true}
                  applicationSumData={checkedRow.reduce(
                    (accumulator, { price_sum }) => accumulator + price_sum,
                    0
                  )}
                  applicationSumDataUAH={checkedRow.reduce(
                    (accumulator, { price_in_uah }) =>
                      accumulator + price_in_uah,
                    0
                  )}
                />
              </tr>
            </thead>
            <tbody>
              <BodyTable
                handleCounterApplication={handleCounterApplication}
                onChangeApplicationQuantity={onChangeApplicationQuantity}
                cancelItemTableFunc={cancelItemTableFunc}
                data={checkedRow}
                columns={column}
              />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ApplicationCatalogue;
