import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addAttrCategoryTable } from "../../../../../../store/slices/tableSlice";
import SubAttributes from "./SubAttributes";
import { useDispatch } from "react-redux";
import { ReactComponent as BugerIcon } from "../../../../../../style/img/burger_icon.svg";
const FormAttributeSub = ({ category_id, cat_data }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <div
        className={`modalDropdown-category-wrapper ${open ? "open" : "close"} `}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onClick={() => setOpen(!open)}
      >
        <span className="catalog_sideitem_titel">
          Фільтри товару для підкатегорії
        </span>
        <BugerIcon />
      </div>

      <Formik
        initialValues={{
          title_attribute_ua: "",
          title_attribute_ru: "",
        }}
        validationSchema={Yup.object({
          title_attribute_ua: Yup.string().min(2, "min 2").required("required"),
          title_attribute_ru: Yup.string().min(2, "min 2").required("required"),
        })}
        onSubmit={async (body, { resetForm }) => {
          const req = await dispatch(
            addAttrCategoryTable({ id: category_id, data: body })
          );

          if (req.payload === 200) resetForm();
        }}
      >
        {({}) => (
          <div
            style={{
              maxHeight: open ? "500px" : "0px",
              overflow: "hidden",
              transition: "max-height 0.5s ease-in-out",
            }}
          >
            {open && (
              <>
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "47%" }}>
                      <ErrorMessage
                        name="title_attribute_ua"
                        className="error"
                        component="div"
                      />
                      <span style={{ padding: " 0 0 5px 5px" }}>Назва ua</span>
                      <Field
                        className="form__input"
                        type="text"
                        placeholder="Назва"
                        name="title_attribute_ua"
                      />
                    </div>
                    <div style={{ width: "47%" }}>
                      <ErrorMessage
                        name="title_attribute_ru"
                        className="error"
                        component="div"
                      />
                      <span style={{ padding: " 0 0 5px 5px" }}>Назва ru</span>
                      <Field
                        className="form__input"
                        type="text"
                        placeholder="Назва"
                        name="title_attribute_ru"
                      />
                    </div>
                  </div>

                  <button type="submit">Додати фільтр</button>
                </Form>
                <SubAttributes
                  category_id={cat_data.id}
                  data={cat_data?.attributes}
                />
              </>
            )}
          </div>
        )}
      </Formik>
    </>
  );
};

export default FormAttributeSub;
