import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { createProductPost } from "../../../../store/slices/productSlice";
import button_back from "../../../../style/img/button_back.png";
import ProductFiltersForm from "./ProductFiltersForm";
import ProductPhoto from "./ProductPhoto";
import { generateKey } from "../../../../utils/generateKey";
import Spinner from "../../../../spiner/Spinner";
import "./catalogueCreate.css";
const CatalogueCreateProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedCategory = useSelector((state) => state.table.selectedCategory);

  // req attribute for filter
  const [attributesReq, setAttributesReq] = useState(
    selectedCategory.attributes.map((attribute) => ({
      value_ru: "",
      value_ua: "",
      ...attribute,
    }))
  );
  const onChangeAttributeReq = ({ key, data, id }) => {
    setAttributesReq((prevAttributes) => {
      const updatedAttributes = prevAttributes.map((item) => {
        if (item.id === id) {
          return { ...item, [key]: data };
        }
        return item;
      });
      return updatedAttributes;
    });
  };
  // addition attributes
  const attributeObject = {
    title_ua: "",
    title_ru: "",
    value_ua: "",
    value_ru: "",
  };
  const [attributesAddNew, setAttributesAddNew] = useState({
    ...attributeObject,
    type: "sub",
  });
  const OnChangeAttributeNew = ({ key, data }) => {
    setAttributesAddNew((prevAttributes) => ({
      ...prevAttributes,
      [key]: data,
    }));
  };

  const [attributesAdd, setAttributesAdd] = useState([]);
  // add addition attr
  const submitNewAttribute = (attr) => {
    setAttributesAdd((items) => [{ ...attr, id: generateKey() }, ...items]);
    setAttributesAddNew({
      ...attributeObject,
      type: "sub",
    });
  };
  const onChangeAttributeAdd = ({ key, data, id }) => {
    setAttributesAdd((prevAttributes) => {
      const updatedAttributes = prevAttributes.map((item) => {
        if (item.id === id) {
          return { ...item, [key]: data };
        }
        return item;
      });
      return updatedAttributes;
    });
  };

  const cancelAttributeAdd = ({ id }) => {
    setAttributesAdd((prevAttributes) =>
      prevAttributes.filter((item) => item.id !== id)
    );
  };

  // add photo like attribute
  const [mainPhoto, setMainPhoto] = useState();

  const OnChangePhotoMain = (field, value) => {
    setMainPhoto(value);
  };
  const cancelMainImage = () => setMainPhoto();

  const OnChangePhotoSub = (field, value) => {
    const data = {
      id: generateKey(),
      file: value,
      type: "img",
    };

    setAttributesAdd((items) => [data, ...items]);
  };
  const cancelSubImage = (id) => {
    setAttributesAdd((items) => items.filter((item) => item.id !== id));
  };
  const { error, loading } = useSelector((state) => state.product);
  console.log(loading);

  // ready data
  const attributes_addition = attributesAdd
    ? attributesAdd.filter((item) => item.type === "sub")
    : null;

  const sub_images = attributesAdd
    ? attributesAdd.filter((item) => item.type === "img")
    : null;
  return (
    <div className="catalogueCreate">
      {loading && <Spinner />}
      <div className="catalogueCreate_head">
        <img
          onClick={() => navigate("/catalogue")}
          src={button_back}
          alt="back"
        />
        <span>Новий товар</span>
        {error && (
          <span style={{ marginLeft: "100px" }} className="error">
            {error}
          </span>
        )}
      </div>
      <Formik
        initialValues={{
          name_ru: "",
          name_ua: "",
          comment: "",
          price: "",
          vendor_code: "",
          measured: "шт.",
        }}
        validationSchema={Yup.object({
          name_ru: Yup.string().required("Name is required"),
          name_ua: Yup.string().required("Name is required"),
          vendor_code: Yup.string().required("Code is required"),
          measured: Yup.string().required("Code is required"),
          price: Yup.string().required("Price is required"),
        })}
        onSubmit={async (body) => {
          const formData = new FormData();

          Object.entries(body).map(([key, value]) => {
            return formData.append(key, value);
          });

          formData.append("categories_id", selectedCategory.id);

          if (attributesReq) {
            const data = JSON.stringify(attributesReq);
            formData.append("attributes_req", data);
          }
          if (attributes_addition) {
            const data = JSON.stringify(attributes_addition);
            formData.append("attributes_addition", data);
          }

          // sub images
          if (sub_images.length > 0) {
            sub_images.map(({ file }) => formData.append("sub_images", file));
          }

          formData.append("main_img", mainPhoto);

          const req = await dispatch(createProductPost(formData));

          if (req.payload === 200) navigate("/catalogue");
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <div>
                  <div className="wrapperSpanAndError">
                    <span>Найменування українською</span>
                    <ErrorMessage
                      name="name_ua"
                      className="error"
                      component="span"
                    />
                  </div>

                  <Field type="text" name="name_ua" />
                </div>
                <div>
                  <span>Найменування російською</span>
                  <ErrorMessage
                    name="name_ru"
                    className="error"
                    component="span"
                  />
                  <Field type="text" name="name_ru" />
                </div>
              </div>

              <div className="catalogueCreate_input_top_second">
                <div>
                  <div className="wrapperSpanAndError">
                    <span>Облікова ціна</span>
                    <ErrorMessage
                      name="price"
                      className="error"
                      component="span"
                    />
                  </div>

                  <Field type="number" name="price" />
                </div>
                <div>
                  <ErrorMessage
                    name="vendor_code"
                    className="error"
                    component="span"
                  />
                  <span>Артикул</span>
                  <Field type="text" name="vendor_code" />
                </div>
                <div>
                  <ErrorMessage
                    name="measured"
                    className="error"
                    component="span"
                  />
                  <span>Вимірюється</span>
                  <Field type="text" name="measured" />
                </div>
              </div>
            </div>
            <div className="catalogueCreate_input_top_textarea">
              <span>Коментар</span>
              <Field as="textarea" type="text" name="comment" />
            </div>
          </div>

          <ProductPhoto
            onChangePhotoMain={OnChangePhotoMain}
            mainPhoto={mainPhoto}
            subPhoto={sub_images}
            OnChangePhotoSub={OnChangePhotoSub}
            cancelSubImage={cancelSubImage}
            cancelMainImage={cancelMainImage}
          />

          <ProductFiltersForm
            attributesReq={attributesReq}
            onChangeAttributeReq={onChangeAttributeReq}
            attributesAddNew={attributesAddNew}
            OnChangeAttributeNew={OnChangeAttributeNew}
            attributesAdd={attributes_addition}
            onChangeAttributeAdd={onChangeAttributeAdd}
            submitNewAttribute={submitNewAttribute}
            cancelAttributeAdd={cancelAttributeAdd}
          />

          <button className="catalogueCreate_submit" type="submit">
            Додати
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default CatalogueCreateProduct;
