import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../axios";
import { changeDateFormat } from "../../utils/changeDateFormat";
export const createCounterpartiesPost = createAsyncThunk(
  "counterparty/createCounterpartiesPost",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post("/counterparty", formData);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCounterparties = createAsyncThunk(
  "counterparty/getCounterparties",
  async ({ offset = 0, limit = 25 }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api(`/counterparty/?offset=${offset}&limit=${limit}`);

      dispatch(setCounterparties(req.data.data.counterparties));
      dispatch(setTotalCounterparties(req.data.data.totalCounterparties));

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// counterparty invoice
export const createCounterpartiesInvoice = createAsyncThunk(
  "counterparty/createCounterpartiesInvoice",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post("/invoices", formData);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const cancelCounterpartiesInvoice = createAsyncThunk(
  "counterparty/cancelCounterpartiesInvoice",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.delete(`/invoices`, { data: { ids: data } });

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCounterpartiesInvoices = createAsyncThunk(
  "counterparty/getCounterpartiesInvoicesByType",
  async ({ offset = 0, limit = 25, search }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api(
        `/invoices/?&offset=${offset}&limit=${limit}${search}`
      );

      dispatch(setCounterpartiesInvoices(req.data.data.invoices));
      dispatch(setTotalCounterpartiesInvoices(req.data.data.total));

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCounterparty = createAsyncThunk(
  "counterparty/deleteCounterparty",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.delete("/counterparty", { data: { ids: data } });

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateDiscountCounterparty = createAsyncThunk(
  "counterparty/updateDiscountCounterparty",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.put("/counterparty", data);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  counterparties: [],
  totalCounterparty: null,
  totalInvoices: null,
  invoices: [],
};

const counterpartySlice = createSlice({
  name: "counterparty",
  initialState,
  reducers: {
    setCounterparties(state, action) {
      state.counterparties = action.payload;
    },
    setTotalCounterparties(state, action) {
      state.totalCounterparty = action.payload;
    },
    setCounterpartiesInvoices(state, { payload }) {
      state.invoices = payload.map((invoice) => {
        const time = changeDateFormat(invoice.createdAt);
        return {
          ...time,
          ...invoice,
        };
      });
    },
    setTotalCounterpartiesInvoices(state, action) {
      state.totalInvoices = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setCounterparties,
  setTotalCounterparties,
  setCounterpartiesInvoices,
  setTotalCounterpartiesInvoices,
} = counterpartySlice.actions;

export default counterpartySlice.reducer;
