import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { putProduct } from "../../../../store/slices/productSlice";
import Spinner from "../../../../spiner/Spinner";
import { API_IMG_URL } from "../../../../axios";
import button_back from "../../../../style/img/button_back.png";
import ProductFiltersForm from "./ProductFiltersForm";
import ProductPhoto from "./ProductPhoto";
import { generateKey } from "../../../../utils/generateKey";
import "./catalogueCreate.css";
const CatalogueDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const product = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];

  const [edit, setEdit] = useState(false);

  const selectedCategory = useSelector((state) => state.table.selectedCategory);

  // req attribute for filter
  const [attributesReq, setAttributesReq] = useState();

  const onChangeAttributeReq = ({ key, data, id }) => {
    setAttributesReq((prevAttributes) => {
      const updatedAttributes = prevAttributes.map((item) => {
        if (item.id === id) {
          return { ...item, [key]: data };
        }
        return item;
      });
      return updatedAttributes;
    });
  };
  // addition attributes
  const attributeObject = {
    title_ua: "",
    title_ru: "",
    value_ua: "",
    value_ru: "",
  };
  const [attributesAddNew, setAttributesAddNew] = useState({
    ...attributeObject,
    type: "addition",
  });
  const OnChangeAttributeNew = ({ key, data }) => {
    setAttributesAddNew((prevAttributes) => ({
      ...prevAttributes,
      [key]: data,
    }));
  };

  const [attributesAdd, setAttributesAdd] = useState([]);

  // add addition attr
  const submitNewAttribute = (attr) => {
    setAttributesAdd((items) => [{ ...attr, id: generateKey() }, ...items]);
    setAttributesAddNew({
      ...attributeObject,
      type: "addition",
    });
  };

  const onChangeAttributeAdd = ({ key, data, id }) => {
    setAttributesAdd((prevAttributes) => {
      const updatedAttributes = prevAttributes.map((item) => {
        if (item.id === id) {
          return { ...item, [key]: data };
        }
        return item;
      });
      return updatedAttributes;
    });
  };

  const cancelAttributeAdd = ({ id }) => {
    setAttributesAdd((prevAttributes) =>
      prevAttributes.filter((item) => item.id !== id)
    );
  };

  const cancelAttributeReq = ({ id }) => {
    setAttributesReq((prevAttributes) =>
      prevAttributes.filter((item) => item.id !== id)
    );
  };

  // add photo like attribute
  const [mainPhoto, setMainPhoto] = useState();

  useEffect(() => {
    setMainPhoto(`${API_IMG_URL}/${product.img_path}`);
  }, [product]);

  const OnChangePhotoMain = (field, value) => {
    setMainPhoto(value);
  };
  const cancelMainImage = () => setMainPhoto();

  const OnChangePhotoSub = (field, value) => {
    const data = {
      id: generateKey(),
      file: value,
      type: "image",
    };

    setAttributesAdd((items) => [data, ...items]);
  };

  const cancelSubImage = (id) => {
    setAttributesAdd((items) => items.filter((item) => item.id !== id));
  };
  const { error, loading } = useSelector((state) => state.product);

  // ready data
  const attributes_addition = attributesAdd
    ? attributesAdd.filter((item) => item.type === "addition")
    : null;

  const sub_images = attributesAdd
    ? attributesAdd.filter((item) => item.type === "image")
    : null;

  useEffect(() => {
    const attAdd = product.Attributes.filter(
      (item) => item.type === "addition"
    ).map(({ product_has_attributes, ...rest }) => ({
      ...product_has_attributes,
      ...rest,
    }));

    const attrReq = product.Attributes.filter(
      (item) => item.type === "main"
    ).map(({ product_has_attributes, ...rest }) => ({
      ...product_has_attributes,
      ...rest,
    }));
    const newReq =
      selectedCategory?.attributes.map((attribute) => ({
        value_ru: "",
        value_ua: "",
        ...attribute,
      })) || [];
    const attrReqMap = attrReq.reduce((acc, attr) => {
      acc[attr.id] = attr;
      return acc;
    }, {});

    const attrImg = product.Attributes.filter(
      (item) => item.type === "image"
    ).map(({ product_has_attributes }) => ({
      id: generateKey(),
      file: `${API_IMG_URL}/${product_has_attributes.value_ua}`,
      file_path: product_has_attributes.value_ua,
      type: "image",
    }));

    setAttributesAdd((items) => [...attrImg, ...attAdd, ...items]);
    setAttributesReq([
      ...attrReq,
      ...newReq.filter((attr) => !attrReqMap[attr.id]),
    ]);
  }, [product]);

  return (
    <div className="catalogueCreate">
      {loading && <Spinner />}
      <div className="catalogueCreate_head">
        <img
          onClick={() => navigate("/catalogue")}
          src={button_back}
          alt="back"
        />
        <span>Деталi товару</span>
        {error && (
          <span style={{ marginLeft: "100px" }} className="error">
            {error}
          </span>
        )}
      </div>
      <Formik
        initialValues={{
          name_ru: product ? product.name_ru : "",
          name_ua: product ? product.name_ua : "",
          comment: product?.comment || "",

          price:
            product && product.old_price ? product.old_price : product.price,
          new_price: product && product.old_price ? product.price : "",

          vendor_code: product ? product.vendor_code : "",
          available: product ? product.available : false,
          top_product: product ? product.top_product : false,
          new_product: product ? product.new_product : false,
          sale: product ? product.sale : false,
          measured: product ? product.measured : "шт.",
        }}
        validationSchema={Yup.object({
          name_ru: Yup.string().required("Name is required"),
          name_ua: Yup.string().required("Name is required"),
          vendor_code: Yup.string().required("Code is required"),
          price: Yup.string().required("Price is required"),
        })}
        onSubmit={async (body) => {
          const formData = new FormData();

          Object.entries(body).map(([key, value]) => {
            return formData.append(key, value);
          });

          if (attributesReq) {
            const data = JSON.stringify(attributesReq);
            formData.append("attributes_req", data);
          }
          if (attributes_addition) {
            const data = JSON.stringify(attributes_addition);
            formData.append("attributes_addition", data);
          }

          // sub images
          if (sub_images.length > 0) {
            sub_images.map(({ file }) => {
              if (typeof file !== "string") {
                formData.append("sub_images", file);
              }
            });
          }

          if (sub_images.length > 0) {
            sub_images.map((img) => {
              if (img.file_path) {
                formData.append("sub_images_old", img.file_path);
              }
            });
          }

          if (mainPhoto) {
            formData.append("main_img", mainPhoto);
          }

          const req = await dispatch(
            putProduct({ data: formData, id: product.id })
          );

          if (req.payload === 200) setEdit(false);
        }}
      >
        {({ values }) => (
          <Form className="">
            <div className="catalogueCreate_input_top_wrapper">
              <div className="catalogueCreate_input_top">
                <div className="catalogueCreate_input_top_first">
                  <div>
                    <div className="wrapperSpanAndError">
                      <span>Найменування українською</span>
                      <ErrorMessage
                        name="name_ua"
                        className="error"
                        component="span"
                      />
                    </div>

                    <Field type="text" name="name_ua" disabled={!edit} />
                  </div>
                  <div>
                    <span>Найменування російською</span>
                    <ErrorMessage
                      name="name_ru"
                      className="error"
                      component="span"
                    />
                    <Field type="text" name="name_ru" disabled={!edit} />
                  </div>
                </div>

                <div className="catalogueCreate_input_top_second">
                  <div>
                    <div className="wrapperSpanAndError">
                      <span>Облікова ціна</span>
                      <ErrorMessage
                        name="price"
                        className="error"
                        component="span"
                      />
                    </div>

                    <Field type="number" name="price" disabled={!edit} />
                  </div>
                  <div>
                    <ErrorMessage
                      name="vendor_code"
                      className="error"
                      component="span"
                    />
                    <span>Артикул</span>
                    <Field type="text" name="vendor_code" disabled={!edit} />
                  </div>

                  <div>
                    <ErrorMessage
                      name="measured"
                      className="error"
                      component="span"
                    />
                    <span>Вимірюється</span>
                    <Field type="text" name="measured" disabled={!edit} />
                  </div>
                </div>
                <div
                  className="catalogueCreate_input_top_second"
                  style={{ marginTop: "16px" }}
                >
                  <div>
                    <div className="catalogue_checbox-container">
                      <span>Є в наявності</span>
                      <Field
                        style={{ width: "50px" }}
                        type="checkbox"
                        name="available"
                        disabled={!edit}
                      />
                    </div>

                    <div className="catalogue_checbox-container">
                      <span>Топ продукт</span>
                      <Field
                        style={{ width: "50px" }}
                        type="checkbox"
                        name="top_product"
                        disabled={!edit}
                      />
                    </div>
                    <div className="catalogue_checbox-container">
                      <span>Новий продукт</span>
                      <Field
                        style={{ width: "50px" }}
                        type="checkbox"
                        name="new_product"
                        disabled={!edit}
                      />
                    </div>
                    <div className="catalogue_checbox-container_sale">
                      <div className="catalogue_checbox-container">
                        <span>Pозпродаж</span>
                        <Field
                          style={{ width: "50px" }}
                          type="checkbox"
                          name="sale"
                          disabled={!edit}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "0 16px",
                          alignItems: "center",
                        }}
                      >
                        <div className="wrapperSpanAndError">
                          <span style={{ width: "70px", margin: "0" }}>
                            Нова ціна
                          </span>
                          <ErrorMessage
                            name="new_price"
                            className="error"
                            component="span"
                          />
                        </div>

                        <Field
                          type="number"
                          name="new_price"
                          disabled={!edit || !values.sale}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="catalogueCreate_input_top_textarea">
                <span>Коментар</span>
                <Field
                  as="textarea"
                  type="text"
                  name="comment"
                  disabled={!edit}
                />
              </div>
            </div>

            <ProductPhoto
              onChangePhotoMain={OnChangePhotoMain}
              mainPhoto={mainPhoto}
              subPhoto={sub_images}
              OnChangePhotoSub={OnChangePhotoSub}
              cancelSubImage={cancelSubImage}
              cancelMainImage={cancelMainImage}
              disable={!edit}
            />

            <ProductFiltersForm
              attributesReq={attributesReq}
              onChangeAttributeReq={onChangeAttributeReq}
              attributesAddNew={attributesAddNew}
              OnChangeAttributeNew={OnChangeAttributeNew}
              attributesAdd={attributes_addition}
              onChangeAttributeAdd={onChangeAttributeAdd}
              submitNewAttribute={submitNewAttribute}
              cancelAttributeAdd={cancelAttributeAdd}
              cancelAttributeReq={cancelAttributeReq}
              disable={edit}
            />

            <div className="detailsButton_wrapper">
              <div>
                {edit && (
                  <button className="catalogueCreate_submit" type="submit">
                    Зберегти
                  </button>
                )}
              </div>

              <button
                type="button"
                className="catalogueCreate_submit"
                onClick={() => setEdit((state) => !state)}
              >
                Редагувати
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CatalogueDetails;
