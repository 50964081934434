import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/slices/userSlice";
import { NavLink } from "react-router-dom";
import { Twirl as HamburgerIcon } from "hamburger-react";
import { setMovingChecked } from "../../../store/slices/tableSlice";
import { ReactComponent as Catalogue_icon } from "../../../style/img/catalogue_icon.svg";
import { ReactComponent as Counterparty_icon } from "../../../style/img/counterparty_icon.svg";
import { ReactComponent as Users_icon } from "../../../style/img/users_icon.svg";
import { ReactComponent as Sell_icon } from "../../../style/img/sell_icon.svg";
import { ReactComponent as Setting_icon } from "../../../style/img/setting.svg";

import exit_icon from "../../../style/img/exit.png";
import "./asideBarNav.css";

const menuItems = [
  // {
  //   label: "Журнал",
  //   icon: <Log_icon />,
  //   to: "/",
  // },
  {
    label: "Каталог",
    icon: <Catalogue_icon />,
    to: "/catalogue",
  },

  {
    label: "Контрагенти",
    icon: <Counterparty_icon />,
    to: "/counterparty",
  },
  {
    label: "Користувачі",
    icon: <Users_icon />,
    to: "/users",
  },

  {
    label: "Продажі",
    icon: <Sell_icon />,
    to: "/sell",
  },
  {
    label: "Опції",
    icon: <Setting_icon />,
    to: "/setting",
  },
];

const AsideBarNav = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const movingChecked = useSelector((state) => state.table.movingChecked);
  const renderMenuItem = (menuItem) => {
    return (
      <NavLink
        onClick={() => {
          if (movingChecked) {
            dispatch(setMovingChecked());
          }
        }}
        key={menuItem.to}
        className="sideitem"
        to={`${menuItem.to}`}
      >
        <div className="sideitem_svg_wrapper">{menuItem.icon}</div>

        <span className="linkText">{menuItem.label}</span>
      </NavLink>
    );
  };
  return (
    <div className={open ? "sidenav" : "sidenavClosed"}>
      <div>
        <button className="menuBtn" onClick={toggleOpen}>
          <HamburgerIcon size={30} toggled={open} toggle={setOpen} />
        </button>

        <nav className="navbar">
          {menuItems.map((menuItem) => renderMenuItem(menuItem))}
        </nav>
      </div>
      <div className="sideitem" onClick={() => dispatch(logout())}>
        <div>
          <img className="exit_icon_nav" src={exit_icon} alt="exit" />
        </div>

        <span className="linkText sideitem_exitSpan">Вихід</span>
      </div>
    </div>
  );
  // return <nav className='navbar'>{menuItems.map((menuItem) => renderMenuItem(menuItem))}</nav>;
};

export default AsideBarNav;
