import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  deleteCategoryAttribute,
  updateAttrCategoryTable,
} from "../../../../../../store/slices/tableSlice";
import { useDispatch, useSelector } from "react-redux";
import ModalDelAttribute from "./ModalDelAttribute";
const SubAttributes = ({ data, category_id }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.table.loading);

  const deleteFunc = (attr_id) =>
    dispatch(deleteCategoryAttribute({ attr_id, id: category_id }));
  return (
    <div
      style={{
        marginTop: "10px",
        borderTop: "1px solid #dbdbdb",
        borderBottom: "1px solid #dbdbdb",
        padding: "10px 0",
        maxHeight: "200px",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        gap: "10px 0",
      }}
    >
      {data &&
        data.map((item) => (
          <div
            key={item.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 20px",
              border: "1px solid #008947",
              borderRadius: "9px",
              color: "#008947",
              // backgroundColor: "#E8FCEF",
            }}
            className="subAttributes_wrapper"
          >
            {/* <span key={item.id}>{item.title_ua}</span>
            <span>{item.title_ru}</span> */}

            <Formik
              initialValues={{
                title_attribute_ua: item?.title_ua,
                title_attribute_ru: item?.title_ru,
              }}
              validationSchema={Yup.object({
                title_attribute_ua: Yup.string()
                  .min(2, "min 2")
                  .required("required"),
                title_attribute_ru: Yup.string()
                  .min(2, "min 2")
                  .required("required"),
              })}
              onSubmit={async (body, { resetForm }) => {
                const req = await dispatch(
                  updateAttrCategoryTable({ id: item.id, data: body })
                );
              }}
            >
              {({}) => (
                <>
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "47%" }}>
                        <ErrorMessage
                          name="title_attribute_ua"
                          className="error"
                          component="div"
                        />
                        <span style={{ padding: " 0 0 5px 5px" }}>
                          Назва ua
                        </span>
                        <Field
                          className="form__input"
                          type="text"
                          placeholder="Назва"
                          name="title_attribute_ua"
                        />
                      </div>
                      <div style={{ width: "47%" }}>
                        <ErrorMessage
                          name="title_attribute_ru"
                          className="error"
                          component="div"
                        />
                        <span style={{ padding: " 0 0 5px 5px" }}>
                          Назва ru
                        </span>
                        <Field
                          className="form__input"
                          type="text"
                          placeholder="Назва"
                          name="title_attribute_ru"
                        />
                      </div>
                    </div>

                    <button type="submit">
                      {loading ? "Обновляется" : "Обновити фільтр"}
                    </button>
                  </Form>
                </>
              )}
            </Formik>

            <ModalDelAttribute submit={deleteFunc} attr_id={item.id} />
          </div>
        ))}
    </div>
  );
};

export default SubAttributes;
