import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../axios";
import { changeDateFormat } from "../../utils/changeDateFormat";

export const createPaymentPost = createAsyncThunk(
  "paymentSlice/createPaymentPost",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post(`/payment`, data);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const sendPaymentPost = createAsyncThunk(
  "paymentSlice/createPaymentPost",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post(`/sendPayment`, data);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getPayments = createAsyncThunk(
  "paymentSlice/getPayments",
  async (
    { type, offset = 0, limit = 25, search },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const req = await $api(
        `/payment/?type=${type}&offset=${offset}&limit=${limit}${search}`
      );

      dispatch(setData(req.data.data.data));
      dispatch(setTotal(req.data.data.total));

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deletePaymentInvoice = createAsyncThunk(
  "payment/deletePaymentInvoice",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.delete("/payment", { data: { ids: data } });

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const initialState = {
  data: [],
  total: null,
  activeCategory: null,
  checkedCurrency: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setData(state, { payload }) {
      state.data = payload.map((invoice) => {
        const user_name = invoice.User?.name || "";
        const counterparty_name = invoice.Counterparty?.name || "";

        const time = changeDateFormat(invoice.createdAt);
        return {
          user_name,
          counterparty_name,
          ...time,
          ...invoice,
        };
      });
    },

    setTotal(state, { payload }) {
      state.total = payload;
    },
    setCheckedCurrency(state, { payload }) {
      state.checkedCurrency = payload;
    },
    setActiveCategory(state, { payload }) {
      state.activeCategory = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setData, setTotal, setActiveCategory, setCheckedCurrency } =
  paymentSlice.actions;

export default paymentSlice.reducer;
