import React from "react";
import FilterInputs from "./FilterInputs";
import { ReactComponent as Search_icon } from "../../../style/img/search_icon.svg";
import remove_icon from "../../../style/img/remove_gray_icon.png";
const HeaderTable = ({
  dataInput,
  onChangeData,
  columns,
  applicationHeader = false,
  applicationSumData = null,
  applicationSumDataUAH = null,
}) => {
  return columns.map((column) => {
    if (column.accessor === "checkbox") {
      return (
        <th style={{ width: "50px" }} key={column.Header}>
          <div className="tableHeader_title">
            <input
              style={{ width: "0px", height: "0px", margin: "0px" }}
              type="checkbox"
              disabled
              className="chb chb-3"
              id="chb-3"
            />
            <label htmlFor="chb-3"></label>
          </div>
          <div className="wrapper_filter">
            <Search_icon />
          </div>
        </th>
      );
    }
    if (applicationHeader && column.accessor === "delete") {
      return (
        <th key={column.Header} style={{ width: "50px", padding: "0" }}>
          <img style={{ margin: "0 auto" }} src={remove_icon} alt="delete" />
        </th>
      );
    }
    if (applicationHeader && column.accessor === "toggle_q") {
      return (
        <th key={column.Header} style={{ width: "50px", textAlign: "center" }}>
          {column.Header}
        </th>
      );
    }
    if (applicationHeader && column.accessor === "quantity") {
      return (
        <th key={column.Header} style={{ width: "210px" }}>
          {column.Header}
        </th>
      );
    }
    if (
      applicationHeader &&
      column.accessor === "price_sum" &&
      applicationSumData
    ) {
      return (
        <th key={column.Header}>
          {column.Header}: {applicationSumData}
        </th>
      );
    }
    if (
      applicationHeader &&
      column.accessor === "price_in_uah" &&
      applicationSumDataUAH
    ) {
      return (
        <th key={column.Header}>
          {column.Header}: {applicationSumDataUAH}
        </th>
      );
    }
    if (applicationHeader) {
      return <th key={column.Header}>{column.Header}</th>;
    }

    return (
      <th key={column.accessor}>
        <div className="tableHeader_title"> {column.Header}</div>
        <div className="wrapper_filter">
          {dataInput[column.accessor] !== undefined && (
            <FilterInputs
              name={column.accessor}
              dataInput={dataInput}
              onChangeData={onChangeData}
            />
          )}
        </div>
      </th>
    );
  });
};
export default HeaderTable;
