import { useState } from "react";
import UseModal from "../../../../../UseModal";
import { ReactComponent as SettingSvg } from "../../../../../../style/img/setting.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCatagory,
  getCategoryTable,
  updateCategoryTable,
} from "../../../../../../store/slices/tableSlice";
import ModalDelete from "../../../ModalDelete";
import FormCategory from "../setting_category/FormCategory";
import FormAttributeSub from "./FormAttributeSub";

const TableSettingSubCategory = ({ category_id, cat_data }) => {
  const categoryData = useSelector((state) => state.table.category);
  const dispatch = useDispatch();
  const [modalCreate, setModalCreate] = useState({
    visible: false,
    item: null,
  });

  const closeModal = () =>
    setModalCreate(() => {
      return {
        item: null,
        visible: false,
      };
    });

  const onModal = () => {
    setModalCreate(() => {
      return {
        item: null,
        visible: true,
      };
    });
  };

  const submitUpdate = (data) => {
    return updateCategoryTable({ data, id: category_id });
  };

  const submitDelete = async (id) => {
    const req = await dispatch(deleteCatagory([id]));
    if (req.payload === 200) {
      dispatch(getCategoryTable());
    }
    return req;
  };

  return (
    <>
      <div className="tableNav_setting_button" onClick={() => onModal()}>
        <SettingSvg />
      </div>

      <UseModal
        visible={modalCreate.visible}
        title="Налаштування"
        content={
          <div className="modalCreate_wrapper">
            <FormCategory
              category_id={category_id}
              closeModal={closeModal}
              submit={submitUpdate}
              id={"cat"}
              title={"Редагувати підкатегорію"}
              button_text={"Зберегти"}
              cat_data={cat_data}
            />

            <FormAttributeSub
              category_id={category_id}
              id={"cat"}
              cat_data={cat_data}
            />

            <div
              className="modal_tableNav_buttonWrapper"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <ModalDelete
                submit={submitDelete}
                checkedRowLength={1}
                checkedIds={category_id}
              />
              <button onClick={closeModal}>Cкасувати</button>
            </div>
          </div>
        }
        onClose={closeModal}
      />
    </>
  );
};

export default TableSettingSubCategory;
