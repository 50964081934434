import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import button_back from "../../../../style/img/button_back.png";
import UseDropDown from "../../../../useHook/dropDown/UseDropDown";
import { getSettingByType } from "../../../../store/slices/settingSlice";
import { updateDiscountCounterparty } from "../../../../store/slices/counterpartySlice";
const CounterpartyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];

  const discounts = useSelector((state) => state.setting.data);
  const [checkedDiscount, setCheckedDiscount] = useState();
  const handleDiscount = (item) => {
    setCheckedDiscount(item);
  };
  useEffect(() => {
    dispatch(getSettingByType({ type: "discount" }));
    if (discounts && user) {
      const findDiscount = discounts.filter(
        (item) => item.value === user?.Discount?.value
      )[0];
      if (findDiscount) {
        setCheckedDiscount({
          id: findDiscount.id,
          name: findDiscount.name,
          value: findDiscount.value,
        });
      }
    }
  }, [user]);

  return (
    <div className="catalogueCreate">
      <div className="catalogueCreate_head">
        <img
          onClick={() => navigate("/counterparty")}
          src={button_back}
          alt="back"
        />
        <span>Деталi контрагент</span>
      </div>
      <Formik
        initialValues={{}}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          const data = body;
          data.id = user.id;
          data.discount = checkedDiscount.id;

          const req = await dispatch(updateDiscountCounterparty(data));

          if (req.payload === 200) navigate("/counterparty");
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div
              className="catalogueCreate_input_top"
              style={{ margin: "0 0 200px 0" }}
            >
              <div style={{ width: "400px" }}>
                <span>Тип цін</span>
                <UseDropDown
                  selectedItem={checkedDiscount}
                  handleChange={handleDiscount}
                  data={discounts}
                  //   disabled={true}
                />
              </div>
            </div>
          </div>

          <button className="catalogueCreate_submit" type="submit">
            Додати
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default CounterpartyDetails;
